import { useMemo } from "react";
import {
  Stack,
  IconButton,
  Link,
  DateField,
  CircularProgress,
} from "@pankod/refine-mui";

import CustomDataGrid from "components/CustomDataGrid";

import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

import axios from "utils/axios";
import { useQuery } from "react-query";

export default function () {
  const { data, status } = useQuery("my-charges", async () => {
    let { data: res } = await axios.get("/api/my-charges");
    return res;
  });

  const columns = useMemo(
    () => [
      {
        field: "date",
        headerName: "Date",
        minWidth: 200,
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <DateField format="LLL" value={params.row.date} />
            </Stack>
          );
        },
      },
      {
        field: "amount",
        headerName: "Amount Paid",
        minWidth: 100,
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              £{params.row.amount}
            </Stack>
          );
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <Link
                href={params.row.receipt}
                target="_blank"
                rel="noreferrer"
                sx={{
                  textDecoration: "none",
                }}
              >
                <IconButton>
                  <ReceiptLongIcon />
                </IconButton>
              </Link>
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      {status != "loading" && data && data.length > 0 && (
        <CustomDataGrid
          title="Orders"
          data={data}
          loading={status == "loading"}
          columns={columns}
          border={5}
        />
      )}
      {status != "loading" && (!data || data.length == 0) && (
        <>
          <img
            src="/empty.png"
            style={{
              width: "75%",
              height: "75%",
              objectFit: "contain",
            }}
          />
          <h3>None Found</h3>
        </>
      )}
      {status == "loading" && <CircularProgress />}
    </>
  );
}
