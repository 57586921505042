import { useState, useEffect } from "react";

import {
  Grid,
  Card,
  MuiList,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@pankod/refine-mui";

import { toast } from "react-toastify";
import axios from "utils/axios";

export default function (props) {
  return (
    <Card sx={{ borderRadius: 5, height: "100%" }}>
      <Grid
        item
        xs={12}
        container
        alignItems="space-between"
        justifyContent="center"
        p={3}
        sx={{ height: "100%" }}
      >
        <h1>Subscriptions</h1>
        <Grid item xs={12} sx={{ padding: 1, width: "100%" }}>
          <MuiList
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            {props?.orders
              ?.filter((order) => order.subscription)
              .map((order) => (
                <ListItem key={order.id}>
                  <ListItemText primary={order.name} secondary={order.status} />
                  <ListItemButton
                    onClick={async () => {
                      try {
                        let { data } = await axios.post(
                          "/api/create-portal-session",
                          {
                            returnUrl: window.location.href,
                            session: order.session,
                          }
                        );
                        window.location = data;
                      } catch (error) {
                        console.log(error);
                        toast.error("Error Managing Plan");
                      }
                    }}
                  >
                    Manage
                  </ListItemButton>
                </ListItem>
              ))}
          </MuiList>
        </Grid>
      </Grid>
    </Card>
  );
}
