import { useState, useEffect, useMemo } from "react";
import { Typography, Card } from "@pankod/refine-mui";
import { DataGrid } from "@mui/x-data-grid";

export default function ({ title, columns, data, loading, border = 1 }) {
  const [pageSize, setPageSize] = useState(10);

  return (
    <Card sx={{ p: 3, borderRadius: border }}>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      <DataGrid
        columns={columns}
        rows={data || []}
        loading={loading}
        rowsPerPageOptions={[10, 25, 50]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        autoHeight
        sx={{
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#f5f5f5",
          },

          "& .MuiDataGrid-cell": {
            borderRight: "0x",
          },

          "& .MuiDataGrid-columnsContainer": {
            borderBottom: "0px",
          },

          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
        }}
      />
    </Card>
  );
}
