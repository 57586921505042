import React from "react";
import { PieChart, pieChartDefaultProps } from "react-minimal-pie-chart";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

function random_rgb() {
  var o = Math.round,
    r = Math.random,
    s = 255;
  let red = o(r() * s);
  let green = o(r() * s);
  let blue = o(r() * s);
  return {
    rgb: "rgba(" + red + "," + green + "," + blue + "," + 1 + ")",
    rgba: "rgba(" + red + "," + green + "," + blue + "," + 0.3 + ")",
  };
}

export default function DoughnutChart({ entries }) {
  const [hovered, setHovered] = React.useState(null);
  const [colors, setColors] = React.useState(
    entries.map((item) => random_rgb())
  );

  React.useEffect(() => {
    setColors(entries.map((item) => random_rgb()));
  }, [entries]);

  return (
    <Grid item sx={{ height: "100%" }}>
      {entries.map((item, index) => (
        <Tooltip
          title={item.name + " - " + item.count}
          key={index}
          followCursor
          placement="top"
        >
          <div
            key={index}
            style={{
              color: hovered === index ? "white" : "black",
              backgroundColor: hovered === index ? "black" : "white",
              padding: "5px",
              borderRadius: "5px",
              display: "inline-block",
              margin: "5px",
            }}
            onMouseOver={() => {
              setHovered(index);
            }}
          >
            {item.name}
          </div>
        </Tooltip>
      ))}
      {colors.length == entries.length && (
        <PieChart
          animate
          rounded
          label={({ dataEntry }) => dataEntry.value}
          labelPosition={120}
          radius={pieChartDefaultProps.radius - 10}
          lineWidth={15}
          data={entries.map((item, index) => {
            return {
              title: item.name,
              value: item.count,
              color: colors[index].rgb,
            };
          })}
          onMouseOver={(_, index) => {
            setHovered(index);
          }}
          onMouseOut={() => {
            setHovered(null);
          }}
          style={{ marginTop: -15 }}
        />
      )}
    </Grid>
  );
}
