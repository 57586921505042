import { Box, TextField } from "@pankod/refine-mui";

export default function ClientFields({ register, control, errors }) {
  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <TextField
        {...register("name", { required: "Name is required" })}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!errors?.name}
        helperText={errors.name?.message}
        margin="normal"
        required
        fullWidth
        id="name"
        label="Name"
        name="name"
        autoFocus
      />
      <TextField
        {...register("code", { required: "Coupon Code is required" })}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!errors?.code}
        margin="normal"
        required
        fullWidth
        id="code"
        label="Coupon Code"
        name="code"
      />
      <TextField
        {...register("discount", {
          required: "Discount Percentage is required",
        })}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!errors?.discount}
        margin="normal"
        required
        fullWidth
        id="discount"
        label="Discount Percentage"
        name="discount"
        type="number"
      />
    </Box>
  );
}
