import { Refine, usePermissions } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider, AuthHelper } from "@pankod/refine-strapi-v4";
import { QueryClient, QueryClientProvider } from "react-query";

import { TOKEN_KEY, API_URL } from "./constants";

import {
  ErrorComponent,
  ReadyPage,
  CssBaseline,
  createTheme,
  ThemeProvider,
  GlobalStyles,
  RefineSnackbarProvider,
  notificationProvider,
} from "@pankod/refine-mui";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "utils/axios";

import CustomLayout from "./components/CustomLayout";
import LoginPage from "./components/LoginPage";
import Dashboard from "pages/Dashboard";
import Settings from "pages/Settings";

import Success from "pages/Success";
import Cancel from "pages/Cancel";
import Unavailable from "pages/Unavailable";

import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import ProductsList from "./pages/products/List";
import ProductsCreate from "./pages/products/Create";
import ProductsEdit from "./pages/products/Edit";

import RedeemIcon from "@mui/icons-material/Redeem";
import CouponsList from "./pages/coupons/List";
import CouponsCreate from "./pages/coupons/Create";
import CouponsEdit from "./pages/coupons/Edit";

import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Receipts from "./pages/Receipts";
import GroupIcon from "@mui/icons-material/Group";
import Customers from "./pages/Customers";

export const themeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#8d99ae",
    },
    secondary: {
      main: "#2b2d42",
    },
    info: {
      main: "#edf2f4",
    },
  },
};

const theme = createTheme(themeOptions);
const queryClient = new QueryClient();
window.queryClient = queryClient;

const App = () => {
  const strapiAuthHelper = AuthHelper(API_URL + "/api");

  const authProvider = {
    login: async ({ username, password }) => {
      const { data, status } = await strapiAuthHelper.login(username, password);
      if (status === 200) {
        localStorage.setItem(TOKEN_KEY, data.jwt);
        localStorage.setItem("user", JSON.stringify(data.user));
        // set header axios instance
        axios.defaults.headers.common.Authorization = `Bearer ${data.jwt}`;

        return Promise.resolve();
      }
      return Promise.reject();
    },
    logout: () => {
      localStorage.removeItem(TOKEN_KEY);
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        return Promise.resolve();
      }

      return Promise.reject();
    },
    getPermissions: () => {
      const auth = localStorage.getItem("user");
      if (auth) {
        const parsedUser = JSON.parse(auth);
        return Promise.resolve(parsedUser.role.type);
      }
      return Promise.reject();
    },
    getUserIdentity: async () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (!token) {
        return Promise.reject();
      }
      const { data, status } = await strapiAuthHelper.me(token);
      localStorage.setItem("user", JSON.stringify(data));
      if (status === 200) {
        const { id, username, email } = data;
        return Promise.resolve({
          id,
          username,
          email,
        });
      }

      return Promise.reject();
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <QueryClientProvider client={queryClient}>
          <Refine
            routerProvider={{
              ...routerProvider,
              routes: [
                {
                  element: <Success />,
                  path: "/success",
                  layout: false,
                },
                {
                  element: <Cancel />,
                  path: "/cancel",
                  layout: false,
                },
                {
                  element: <Unavailable />,
                  path: "/unavailable",
                  layout: false,
                },
                {
                  element: <Settings />,
                  path: "/settings",
                  layout: true,
                },
              ],
            }}
            resources={[
              {
                name: "products",
                icon: <AddBusinessIcon />,
                options: { label: "Products" },
                list: ProductsList,
                create: ProductsCreate,
                edit: ProductsEdit,
              },
              {
                name: "coupons",
                icon: <RedeemIcon />,
                options: { label: "Coupons" },
                list: CouponsList,
                create: CouponsCreate,
                edit: CouponsEdit,
              },
              {
                name: "charges",
                icon: <ReceiptLongIcon />,
                options: { label: "Receipts" },
                list: Receipts,
              },
              {
                name: "customers",
                icon: <GroupIcon />,
                options: { label: "Customers" },
                list: Customers,
              },
            ]}
            authProvider={authProvider}
            dataProvider={DataProvider(API_URL + "/api", axios)}
            notificationProvider={notificationProvider}
            Title={({ collapsed }) => (
              <div
                style={{
                  height: 200,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {collapsed && (
                  <img src="/logo.png" alt="Logo" style={{ width: "50px" }} />
                )}
                {!collapsed && (
                  <img src="/logo.png" alt="Logo" style={{ width: "100px" }} />
                )}
              </div>
            )}
            Layout={CustomLayout}
            DashboardPage={Dashboard}
            ReadyPage={ReadyPage}
            LoginPage={LoginPage}
            catchAll={<ErrorComponent />}
          />
        </QueryClientProvider>
      </RefineSnackbarProvider>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
