import { useState, useEffect } from "react";

import { Grid, Card, Tabs, Tab, Box, Typography } from "@pankod/refine-mui";

import { authProvider } from "../authProvider";

export default function Dashboard() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const data = await authProvider.getUserIdentity();
      setUser(data);
    };
    fetchUser();
  }, []);

  return (
    <Grid
      container
      spacing={5}
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh" }}
    >
      <Grid item xs={5}>
        <Card sx={{ borderRadius: 10, height: "100%" }}>
          <Grid
            item
            xs={12}
            container
            alignItems="space-between"
            justifyContent="center"
            p={3}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12} container justifyContent="center">
              <img
                src="/unavailable.png"
                alt="cancel"
                style={{ width: 300, height: 300, marginBottom: 10 }}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <h1 style={{ textAlign: "center" }}>
                Sorry, the product you were trying to purchase is not available
                at the moment!
              </h1>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <p>You can close this window or go back</p>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
