import { useMemo } from "react";
import { Stack, Button, Link, DateField } from "@pankod/refine-mui";

import CustomDataGrid from "components/CustomDataGrid";

import axios from "utils/axios";
import { useQuery } from "react-query";

export default function CustomerList() {
  const { data, status } = useQuery("customers", async () => {
    let { data: res } = await axios.get("/api/customers");
    return res;
  });

  const columns = useMemo(
    () => [
      {
        field: "created",
        headerName: "Date Created",
        minWidth: 300,
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <DateField format="LLL" value={params.row.date} />
            </Stack>
          );
        },
      },
      {
        field: "name",
        headerName: "Customer Name",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "email",
        headerName: "Customer Email",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "phone",
        headerName: "Customer Phone",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "address",
        headerName: "Customer Country",
        minWidth: 200,
        flex: 1,
      },
    ],
    []
  );

  return (
    <CustomDataGrid
      title="Customers"
      columns={columns}
      data={data}
      loading={status == "loading"}
    />
  );
}
