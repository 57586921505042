import { useState, useEffect } from "react";

import {
  Grid,
  Card,
  Tabs,
  Tab,
  Box,
  Typography,
  CircularProgress,
} from "@pankod/refine-mui";

import { authProvider } from "../authProvider";
import axios from "utils/axios";
import LineChart from "components/LineChart";
import DoughnutChart from "components/DoughnutChart";

import PlanCard from "components/user/PlanCard";
import MyOrders from "components/user/MyOrders";

import { normalizeData } from "utils/utils";

const renderAdminDashboard = (charges, products, loading) => {
  return (
    <Grid item xs={12} container justifyContent="space-evenly">
      <Grid item md={5} xs={11} mb={2}>
        {/* Revenue Chart --------------------------------------------- */}
        <Card sx={{ borderRadius: 10, height: "100%" }}>
          <Grid
            item
            xs={12}
            container
            alignItems="space-between"
            justifyContent="center"
            p={3}
            sx={{ height: "100%" }}
          >
            <h1>
              Revenue: £
              {charges?.reduce((acc, curr) => acc + curr.value, 0).toFixed(2)}
            </h1>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: 350, borderRadius: 5, padding: 2 }}
            >
              {!loading && charges && charges.length > 0 && (
                <LineChart entries={charges} />
              )}
              {!loading && (!charges || charges.length == 0) && (
                <>
                  <img
                    src="/empty.png"
                    style={{
                      width: "75%",
                      height: "75%",
                      objectFit: "contain",
                    }}
                  />
                  <h3>None Found</h3>
                </>
              )}
              {loading && <CircularProgress />}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item md={5} xs={11} mb={2}>
        {/* Sales Chart --------------------------------------------- */}
        <Card sx={{ borderRadius: 10, height: "100%" }}>
          <Grid
            item
            xs={12}
            container
            alignItems="space-between"
            justifyContent="center"
            p={3}
            sx={{ height: "100%" }}
          >
            <h1>Products Sold</h1>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: 350, borderRadius: 5, padding: 2 }}
            >
              {!loading && (!products || products.length == 0) && (
                <>
                  <img
                    src="/empty.png"
                    style={{
                      width: "75%",
                      height: "75%",
                      objectFit: "contain",
                    }}
                  />
                  <h3>None Found</h3>
                </>
              )}
              {!loading && products && charges.length > 0 && (
                <DoughnutChart entries={products || []} />
              )}
              {loading && <CircularProgress />}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
const renderCustomerDashboard = (orders) => {
  return (
    <Grid item xs={12} container justifyContent="space-evenly">
      {/* PLAN --------------------------------------------------------------------------- */}
      {orders.length > 0 && (
        <Grid item md={5} xs={11} mb={2}>
          <PlanCard orders={orders} />
        </Grid>
      )}
      {/* ORDERS --------------------------------------------------------------------------- */}
      <Grid item md={5} xs={11} mb={2}>
        <MyOrders />
      </Grid>
    </Grid>
  );
};

export default function Dashboard() {
  const [user, setUser] = useState(null);
  const [charges, setCharges] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      const { data } = await axios.get("/api/users/me");
      setUser(data);
      if (data.role.type == "admin") {
        let charges = await axios.get("/api/charges");
        let products = [];

        // aggregate charges by amount
        charges.data.forEach((charge) => {
          if (!products.find((x) => x.amount == charge.amount)) {
            products.push({ amount: charge.amount, count: 1 });
          } else {
            products.find((x) => x.amount == charge.amount).count++;
          }
        });
        let productsData = [];
        for (let product of products) {
          let { data } = await axios.get(
            `/api/products?filters[price][$eq]=${product.amount * 100}`
          );
          if (data.length > 0) {
            let norm = normalizeData(data[0]);
            product = { ...norm, count: product.count };
            productsData.push(product);
          }
        }
        setProducts(productsData);

        // aggregate all charges by date
        charges = charges.data.reduce((acc, curr) => {
          const date = new Date(curr.date).toLocaleDateString();
          if (acc[date]) {
            acc[date] += curr.amount;
          } else {
            acc[date] = curr.amount;
          }
          return acc;
        }, {});
        // convert to array of objects
        charges = Object.keys(charges).map((key) => ({
          label: key,
          value: charges[key],
        }));

        setCharges(charges.reverse());
      }
      setLoading(false);
    };
    fetchUser();
  }, []);

  return (
    <Grid container spacing={5} justifyContent="space-evenly">
      <Grid item md={5} xs={11} sx={{ mt: -7, mb: 3 }}>
        <Card sx={{ borderRadius: 10, height: "100%" }}>
          <Grid
            item
            xs={12}
            container
            alignItems="space-between"
            p={3}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12} container justifyContent="center">
              {user && (
                <h1 style={{ paddingTop: 10 }}>{`Welcome ${user.username}`}</h1>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {user?.role.type == "admin" &&
        renderAdminDashboard(charges, products, loading)}

      {user?.role.type == "customer" &&
        renderCustomerDashboard(
          user.orders?.filter((order) => order.subscription)
        )}
    </Grid>
  );
}
