import { useMemo, useState, useEffect } from "react";
import { Stack, Button, Link, DateField } from "@pankod/refine-mui";

import CustomDataGrid from "components/CustomDataGrid";

import axios from "utils/axios";
import { useQuery } from "react-query";

export default function IngredientList() {
  const { data, status } = useQuery("charges", async () => {
    let { data: res } = await axios.get("/api/charges");
    return res;
  });

  const columns = useMemo(
    () => [
      {
        field: "date",
        headerName: "Date",
        minWidth: 300,
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <DateField format="LLL" value={params.row.date} />
            </Stack>
          );
        },
      },
      {
        field: "customerName",
        headerName: "Customer Name",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "customerEmail",
        headerName: "Customer Email",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "amount",
        headerName: "Amount Paid",
        minWidth: 50,
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              £{params.row.amount}
            </Stack>
          );
        },
      },
      {
        field: "paid",
        headerName: "Paid",
        minWidth: 50,
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row.paid ? "Yes" : "No"}
            </Stack>
          );
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 50,
        flex: 1,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <Link
                href={params.row.receipt}
                target="_blank"
                rel="noreferrer"
                sx={{
                  textDecoration: "none",
                }}
              >
                <Button fullWidth>View Receipt</Button>
              </Link>
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <CustomDataGrid
      title="Receipts"
      columns={columns}
      data={data}
      loading={status == "loading"}
    />
  );
}
