import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  {
    id: "uniqueid5", //typescript crashes without id
  }
);

function random_rgb() {
  var o = Math.round,
    r = Math.random,
    s = 255;
  let red = o(r() * s);
  let green = o(r() * s);
  let blue = o(r() * s);
  return {
    rgb: "rgba(" + red + "," + green + "," + blue + "," + 1 + ")",
    rgba: "rgba(" + red + "," + green + "," + blue + "," + 0.3 + ")",
  };
}

export default function LineChart({ entries }) {
  let col = random_rgb();
  const labels = entries.map((item) => item.label.slice(0, 10));
  const data = {
    labels,
    datasets: [
      {
        label: "Sales in £",
        data: entries.map((item) => item.value),
        fill: "start",
        borderColor: col.rgb,
        backgroundColor: col.rgba,
        tension: 0.5,
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 0,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
  };

  return <Line options={options} data={data} style={{ width: "100%" }} />;
}
