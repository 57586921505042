import { useMemo, useState } from "react";
import {
  EditButton,
  DeleteButton,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  useDataGrid,
  DataGrid,
  List,
  MuiList,
  ListItemButton,
  ListItemText,
} from "@pankod/refine-mui";

import { API_URL } from "../../constants";

import { toast } from "react-toastify";
import axios from "utils/axios";

export default function ProductsList() {
  const [selectingHook, setSelectingHook] = useState(false);
  const [product, setProduct] = useState(null);

  // prepare data for hooks
  const customer = {
    address: {
      city: null,
      country: "GB",
      line1: null,
      line2: null,
      postal_code: null,
      state: null,
    },
    email: "email@mail.com",
    name: "Name Surname",
    phone: "1234567890",
    tax_exempt: "none",
    tax_ids: [],
  };
  const payment_status = "paid";
  const total = 1;

  const columns = [
    {
      field: "subscription",
      headerName: "Is Sub",
      flex: 1,
      minWidth: 100,
      renderCell: function render(params) {
        return (
          <Stack direction="row" spacing={1}>
            {params.row.subscription ? "Yes" : "No"}
          </Stack>
        );
      },
    },
    { field: "name", headerName: "Name", flex: 1, minWidth: 200 },
    { field: "price", headerName: "Price", flex: 1, minWidth: 100 },
    {
      field: "sales",
      headerName: "Sales",
      flex: 1,
      minWidth: 100,
      renderCell: function render(params) {
        return (
          <Stack direction="row" spacing={1}>
            {params.row.current_sales || "0"} / {params.row.limit || "∞"}
          </Stack>
        );
      },
    },
    { field: "priceID", headerName: "Price ID", flex: 1, minWidth: 300 },
    {
      field: "Test",
      headerName: "Shop",
      minWidth: 230,
      renderCell: function render(params) {
        return (
          <Stack direction="row" spacing={1}>
            <Button
              onClick={async () => {
                try {
                  let { data } = await axios.get(
                    "/api/create-checkout-session/" +
                      params.row.id +
                      "?test=true"
                  );
                  window.location = data;
                } catch (error) {
                  console.log(error);
                }
              }}
              variant="contained"
              color="primary"
              size="small"
            >
              TEST
            </Button>
            <Button
              onClick={() => {
                // copy to clipboard
                navigator.clipboard.writeText(
                  API_URL + "/api/create-checkout-session/" + params.row.id
                );
                toast.success("Copied to clipboard");
              }}
              variant="contained"
              color="primary"
              size="small"
            >
              Copy
            </Button>
            <Button
              disabled={
                !params.row.webhook_test || params.row.webhook_test.length == 0
              }
              onClick={() => {
                setProduct(params.row);
                setSelectingHook(true);
              }}
              variant="contained"
              color="primary"
              size="small"
            >
              Hook
            </Button>
          </Stack>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      minWidth: 50,
      renderCell: function render(params) {
        return (
          <Stack direction="row" spacing={1}>
            <EditButton hideText recordItemId={params.row.id} />
            <DeleteButton hideText recordItemId={params.row.id} />
          </Stack>
        );
      },
    },
  ];

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid();

  return (
    <List
      resource="products"
      createButtonProps={{ size: "small" }}
      cardHeaderProps={{
        title: <Typography variant="h5">Products</Typography>,
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
      <Dialog onClose={() => setSelectingHook(false)} open={selectingHook}>
        <DialogTitle>Select Hook To Test</DialogTitle>
        <MuiList sx={{ pt: 0 }}>
          <ListItemButton
            autoFocus
            onClick={() => {
              console.log("Order Paid");
              axios.post(product.webhook_test, {
                event: "order_paid",
                product,
                customer,
                payment_status,
                total,
                user_password: "password",
              });
            }}
          >
            <ListItemText primary="Order Paid" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              console.log("Subscription Created");
              product.interval = "week";
              product.interval_count = 2;
              axios.post(product.webhook_test, {
                event: "subscription_created",
                product,
                customer,
              });
            }}
          >
            <ListItemText primary="Subscription Created" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              console.log("Subscription Updated", product);
              axios.post(product.webhook_test, {
                event: "subscription_updated",
                type: "cancel/renew",
                product,
                customer,
              });
            }}
          >
            <ListItemText primary="Subscription Updated" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              console.log("Subscription Deleted");
              axios.post(product.webhook_test, {
                event: "subscription_deleted",
                product,
                customer,
              });
            }}
          >
            <ListItemText primary="Subscription Deleted" />
          </ListItemButton>
        </MuiList>
      </Dialog>
    </List>
  );
}
