import { Create } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";

import Fields from "./Fields";

export default function MealCreate() {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    control,
    formState: { errors },
  } = useForm();

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Fields register={register} control={control} errors={errors} />
    </Create>
  );
}
