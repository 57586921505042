import { useState } from "react";
import { Edit, Box, Tabs, Tab } from "@pankod/refine-mui";
import { useForm, Controller } from "@pankod/refine-react-hook-form";

import Fields from "./Fields";

export default function MealEdit() {
  const {
    refineCore: { formLoading, queryResult },
    saveButtonProps,
    register,
    control,
    formState: { errors },
  } = useForm();

  return (
    <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Fields register={register} control={control} errors={errors} />
    </Edit>
  );
}
