import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Button,
  Tabs,
  Tab,
  Card,
  Divider,
} from "@pankod/refine-mui";
import axios from "utils/axios";
import { toast } from "react-toastify";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function ClientFields({
  register,
  control,
  errors,
  getValues,
  record,
}) {
  const [tab, setTab] = useState(0);
  const [subscription, setSubscription] = useState(getValues("subscription"));
  const [current_sales, setCurrentSales] = useState(getValues("current_sales"));
  const [interval, setInterval] = useState(getValues("interval"));

  useEffect(() => {
    if (record?.data == null) return;
    setSubscription(record.data.data.subscription);
    setInterval(record.data.data.interval);
    setCurrentSales(record.data.data.current_sales);
  }, [record?.data]);

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <Tabs
        value={tab}
        onChange={(e, newValue) => setTab(newValue)}
        aria-label="simple tabs example"
      >
        <Tab label="Product Info" />
        <Tab label="Subscription" />
        <Tab label="Automation" />
      </Tabs>
      <TabPanel value={tab} index={0}>
        {record && (
          <Grid item md={3} mb={3}>
            <Card sx={{ p: 3, width: "100%" }}>
              <h2>Current Sales: {current_sales}</h2>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  try {
                    await axios.put("/api/products/" + record?.id, {
                      data: {
                        current_sales: 0,
                      },
                    });
                    toast.success("Sales reset");
                  } catch (error) {
                    toast.error(error.message);
                  }
                }}
                sx={{ float: "right" }}
              >
                Reset
              </Button>
            </Card>
          </Grid>
        )}
        <TextField
          {...register("name", { required: "Name is required" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.name}
          helperText={errors.name?.message}
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoFocus
        />
        <TextField
          {...register("price", { required: "Price is required" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.price}
          margin="normal"
          required
          fullWidth
          id="price"
          label="Price"
          name="price"
          type="number"
        />
        <TextField
          {...register("limit", { required: "Limit is required" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.limit}
          defaultValue={-1}
          margin="normal"
          required
          fullWidth
          id="limit"
          label="Limit"
          name="limit"
          type="number"
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Stack direction="row" alignItems="center" spacing={2} mt={3}>
          Subscription
          <Switch
            {...register("subscription", {})}
            control={control}
            checked={subscription}
            defaultChecked={subscription}
            onChange={(e) => {
              setSubscription(e.target.checked);
            }}
            label="Subscription"
            name="subscription"
          />
        </Stack>
        {/* only display the following if subscription is true */}
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            maxHeight: subscription ? "100%" : 0,
            overflow: "hidden",
          }}
        >
          <TextField
            {...register("interval", {})}
            error={!!errors?.interval}
            margin="normal"
            required={subscription}
            fullWidth
            select
            id="interval"
            label="Interval"
            name="interval"
            value={interval}
            onChange={(e) => {
              setInterval(e.target.value);
            }}
          >
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
            <MenuItem value="year">Year</MenuItem>
          </TextField>
          <TextField
            {...register("interval_count", {})}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors?.interval_count}
            margin="normal"
            required={subscription}
            fullWidth
            id="interval_count"
            label="Interval Count"
            name="interval_count"
          />
          {/* <TextField
          {...register("trial_period_days", {})}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.trial_period_days}
          margin="normal"
          required={subscription}
          fullWidth
          id="trial_period_days"
          label="Trial Period Days"
          name="trial_period_days"
        /> */}
        </Grid>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <TextField
          {...register("webhook_test", {
            required: "Automation Web Hook Test URL is required",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.webhook_test}
          helperText={errors.webhook_test?.message}
          margin="normal"
          required
          fullWidth
          id="webhook_test"
          label="Automation Web Hook Test URL"
          name="webhook_test"
        />
        <TextField
          {...register("webhook", {
            required: "Automation Web Hook Production URL is required",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.webhook}
          helperText={errors.webhook?.message}
          margin="normal"
          required
          fullWidth
          id="webhook"
          label="Automation Web Hook Production URL"
          name="webhook"
        />
        <Divider sx={{ my: 3 }} />
        <TextField
          {...register("successURL", { required: "Success URL is required" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.successURL}
          helperText={errors.successURL?.message}
          margin="normal"
          required
          fullWidth
          id="successURL"
          label="Success URL"
          name="successURL"
        />
        <TextField
          {...register("cancelURL", { required: "Cancel URL is required" })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.cancelURL}
          helperText={errors.cancelURL?.message}
          margin="normal"
          required
          fullWidth
          id="cancelURL"
          label="Cancel URL"
          name="cancelURL"
        />
        <TextField
          {...register("unavailableURL", {
            required: "Product Unavailable URL is required",
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors?.unavailableURL}
          helperText={errors.unavailableURL?.message}
          margin="normal"
          required
          fullWidth
          id="unavailableURL"
          label="Product Unavailable"
          name="unavailableURL"
        />
      </TabPanel>
    </Box>
  );
}
